exports.components = {
  "component---src-pages-blog-jsx": () => import("./../../../src/pages/blog.jsx" /* webpackChunkName: "component---src-pages-blog-jsx" */),
  "component---src-pages-books-jsx": () => import("./../../../src/pages/books.jsx" /* webpackChunkName: "component---src-pages-books-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-music-jsx": () => import("./../../../src/pages/music.jsx" /* webpackChunkName: "component---src-pages-music-jsx" */),
  "component---src-pages-resume-jsx": () => import("./../../../src/pages/resume.jsx" /* webpackChunkName: "component---src-pages-resume-jsx" */),
  "component---src-pages-talks-jsx": () => import("./../../../src/pages/talks.jsx" /* webpackChunkName: "component---src-pages-talks-jsx" */),
  "component---src-templates-blog-js": () => import("./../../../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */)
}

